<template>
  <div>
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4 pa-6">
        <v-row>
          <!-- carrier services section -->
          <v-col cols="3">
            <draggable :group="{ name: 'carrierServices', pull: false, put: true }" ghostClass="display-none" draggable=".draggable">
              <h2 class="mb-4 text-h5">Carrier Services</h2>
              <v-text-field class="mb-4" v-model="carrierServicesFilter" outlined dense label="Search carrier services"></v-text-field>
              <draggable :list="filteredCarrierServices" :group="{ name: 'carrierServices', pull: 'clone', put: false }" drag-class="grabbing" ghostClass="ghost" :forceFallback="true">
                <div class="my-1" v-for="carrierService in filteredCarrierServices" :key="carrierService.id">
                  <v-chip class="to-grab white--text" :color="generateColor(carrierService.carrier_id, carrierService.id)"> {{ carrierService.service }}</v-chip>
                </div>
              </draggable>
            </draggable>
          </v-col>

          <!-- service groups section -->
          <v-col cols="9">
            <v-row>
              <v-col cols="12">
                <h2 class="text-h5">Service Groups</h2>
                
                <p>Drag services from the left side panel into the right to add them to a service group. 
                   <br/>Services within a service group will be tried from cheapest to most expensive unless they have a higher priority. 
                   <br/><b>Higher priority services will be tried first regardless of their cost.</b>
                   <br/>Eg. if service group UKND has services: A (priority 1 cost £3.50), 
                   B (priority 1 cost £2.00), C (priority 0 cost £1.50). The order of service selection will be: B -> A -> C
               </p>
               
              </v-col>
              <v-col cols="4" md="4" ls="3" xl="3" v-for="(serviceGroup, serviceGroupIndex) in serviceGroups" :key="serviceGroup.id" align="center">
                <v-card outlined class="pa-2">
                  <!-- header -->
                  <h2 style="word-break: break-word">{{ serviceGroup.description }}</h2>
                  <div class="grey--text text-subtitle-2">{{ serviceGroup.service }}</div>
                  <v-divider class="py-0 my-2"></v-divider>
                  <!-- carriers - draggable section -->
                  <v-sheet rounded color="grey lighten-3">
                    <draggable
                      class="pa-1 drag-box justify-center"
                      :list="serviceGroup.carrierServices"
                      group="carrierServices"
                      @change="dragged(...arguments, serviceGroupIndex, serviceGroup.id, serviceGroup.carrierServices)"
                      drag-class="grabbing"
                      ghostClass="ghost"
                      :forceFallback="true"
                    > 
                       <div slot="header" class="ma-1 text-subtitle-2" v-show="serviceGroup.carrierServices.length < 1">Drag carrier service here</div>
                     
                          <v-chip
                           v-for="(carrierService, index) in serviceGroup.carrierServices"
                        :key="index"  
                        small
                        :color="generateColor(carrierService.carrier_id, carrierService.id)"
                        class="ma-1 to-grab white--text justify-center"
                        block
                       
                      >
                        {{ carrierService.service }}  <v-text-field
                                                  v-if="carrierService.carriers_service_delivery_service"
                                                  v-model="carrierService.carriers_service_delivery_service.priority"
                                                  hide-details
                                                  type="number"
                                                  class="pa-0 ma-0 text-field-transparent font-weight-bold"
                                                  dense
                                                  solo
                                                  dark
                                                  @change="updatePriority( serviceGroup.id, serviceGroup.carrierServices)"
                                                  style="max-width: 50px;"
                                                  @focus="event => event.target.select()"
                                                />
                                               
                                                
                      </v-chip>
                         

                        
                     
                     
                    </draggable>
                    
                  </v-sheet>

                  <!-- <v-btn color="red" class="mt-2" outlined small @click="saveServiceGroup(serviceGroup.id, serviceGroup.carrierServices )" :loading="loading">Save</v-btn> -->

                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="2000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import Carriers from '@/services/Carriers.js';
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  data() {
    return {
      validForm: true,
      // Title
      title: 'Carrier Service Groups',
      // Snackbar
      snackbar: false,
      text: '',
      loading: false,

      carrierServicesFilter: '',
      carrierServices: [],
      filteredCarrierServices: [],
      serviceGroups: [],
    };
  },

  watch: {
    carrierServicesFilter: {
      handler(newVal) {
        if (newVal) {
          // filter carriers by text that was searched
          let filter = newVal.toLowerCase();
          let filtered = this.carrierServices.filter((el) => el.service.toLowerCase().indexOf(filter) !== -1);
          this.filteredCarrierServices = filtered;
        } else {
          this.filteredCarrierServices = this.carrierServices;
        }
      },
    },
  },

  created() {
    this.getServiceGroups();
    this.getCarrierServices();
  },
  methods: {
    normaliseNum(range, val, min = 0.01) {
      const modul = val % range === 0 ? 1 : val % range;
      let OldRange = (range - 1)  
      let NewRange = (1 - min)  
      return (((modul - 1) * NewRange) / OldRange) + min
      
    },
    generateColor(primary) {
      const primaryRange = 25;
  
      let primaryVal = this.normaliseNum(primaryRange , primary + 14 ) ;
      return '#' + Math.floor(primaryVal * 16777215).toString(16);
    },
    sortServicesByPriority(){
      for(const serviceGroup of this.serviceGroups){
        serviceGroup.carrierServices.sort((a, b) => 
      Number(b.carriers_service_delivery_service.priority) - Number(a.carriers_service_delivery_service.priority))
      }
      
    },
    updatePriority( serviceGroupId, carrierServices){
      // sorting at this time can mess up the text fields
      // this.sortServicesByPriority()
      this.saveServiceGroup(serviceGroupId, carrierServices)
    },
    dragged(evt, index, deliveryServiceId, carrierServices) {
      //  prevent duplicates
      if (evt.added) {
        let carrierServiceToAdd = evt.added.element;
        // set default priority
        carrierServiceToAdd.carriers_service_delivery_service = {priority: 0}
        let filteredResults = this.serviceGroups[index].carrierServices.filter((el) => el.id !== carrierServiceToAdd.id);
        filteredResults.push(carrierServiceToAdd);
        this.serviceGroups[index].carrierServices = filteredResults;
      }
      this.saveServiceGroup(deliveryServiceId, carrierServices)
    },
    getCarrierServices() {
      this.loading = true
      Carriers.getCarrierServices()
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.carrierServices = response.data;
          this.filteredCarrierServices = this.carrierServices;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
        });
    },
    getServiceGroups() {
      this.loading = true
      Carriers.getServiceGroups(true)
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.serviceGroups = response.data;
          this.sortServicesByPriority()
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
        });
    },
    saveServiceGroup(deliveryServiceId, carrierServices){
      this.loading = true
      let carrierServicesInsert = []
      for(let carrierService of carrierServices){
        const carrierServiceId = carrierService.id
        const priority = carrierService.carriers_service_delivery_service ? carrierService.carriers_service_delivery_service.priority : 0
        let found = carrierServicesInsert.find(el => el.id === carrierServiceId)
        if(!found){
          carrierServicesInsert.push({id: carrierServiceId, priority: priority})
        }
      }
      let reqBody = {
        delivery_service_id: deliveryServiceId,
        carriers_services: carrierServicesInsert
      }
      Carriers.saveServiceGroup(reqBody)
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = `${error.message}`;
          this.loading = false;
        });
    }
  },
};
</script>
<style>
.container{
  max-width: 80vw !important;
}
h1 {
  font-size: 150% !important;
}
.display-none {
  display: none;
}
.ghost {
  opacity: 1;
  cursor: grabbing !important;
}
.grabbing {
  cursor: grabbing !important;
}
.to-grab {
  cursor: grab !important;
  user-select: none;
}
.v-chip {
  height: auto !important;
}

.v-chip .v-chip__content {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  white-space: pre-wrap;
}

/* Make the text field background transparent */
.text-field-transparent  .v-input__slot  {
  background-color: transparent !important;
  box-shadow: none !important;
  border-color: transparent !important; /* Remove borders */
}


/* Remove the increment/decrement arrows in number input fields */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield; /* Remove the increment/decrement buttons in Firefox */
}

.drag-box {
  min-height: 10vh;
}
</style>
